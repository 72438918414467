export enum FEATURE_FLAGS {
    DBC_PREMIUM_DESIGNS = 'dbc-premium-designs',
    DBC_ACTIVATION_EXPERIMENT_CTA = 'dbc-activation-experiment-cta',
    QR_MULTI_LINK_QR_CODES = 'qr-multi-link-qr-codes',
    QR_CREATE_QR_CODES_CTA = 'qr-create-qr-codes-cta',
    DBC_NEW_LEAD_CAPTURE_FORM = 'new-lead-capture-form',
    DBC_MOBILE_STEPPER = 'dbc-mobile-stepper',
    DBC_SHOW_PWA_IN_DASHBOARD = 'show-pwa-in-dashboard',
    QR_ANALYTICS_BANNER =  'qr-analytics-banner',
    QR_PRICING_SHOW_STARTER_PLAN = 'qr-pricing-show-starter-plan'
}
