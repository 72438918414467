import {BaseModel} from '../global-services/base.model';

export class AccountLimits extends BaseModel {

    child_user: number;
    child_organization: number;
    qrcode: number;
    qrcode_scan: number;
    bulk_qr_row: number;
    markdown_card: number;
    schedule: number;
    form: number;
    qrcode_template: number;
    folder: number;
    place: number;
    custom_domain: number;
    link_page: number;
    links_per_page: number;
    dbc_user_seats: number = 0;
    geo_location_content: number;
    dbc_user_seats_earned: number = 0;
    meta: { churned_incentive_qrcode?: number, availed_incentive_qr?: boolean, dbc_cancellation_incentive?: any, dbc_cancellation_incentive_end: any};
    analytics_data_access_period: number;

    constructor(json?) {
        super();
        if (!json) {
            return;
        }
        this.child_user = json['child_user'];
        this.child_organization = json['child_organization'];
        this.qrcode = json['qrcode']
        this.qrcode_scan = json['qrcode_scan']
        this.bulk_qr_row = json['bulk_qr_row']
        this.markdown_card = json['markdown_card']
        this.schedule = json['schedule']
        this.form = json['form']
        this.qrcode_template = json['qrcode_template']
        this.folder = json['folder']
        this.place = json['place']
        this.custom_domain = json['custom_domain']
        this.link_page = json['link_page']
        this.links_per_page = json['links_per_page'];
        this.dbc_user_seats = json['dbc_user_seats'] || 0;
        this.dbc_user_seats_earned = json['dbc_user_seats_earned'] || 0;
        this.geo_location_content = json['geo_location_content'];
        this.meta = json['meta'] || {};
        this.analytics_data_access_period = json['analytics_data_access_period'];
    }

}
