import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import amplitude from 'amplitude-js';
import {environment} from '../../environments/environment';

export enum AMPLITUDE_EVENT_CATEGORIES {
    Onboarding = 'Onboarding',
    Engagement = 'Engagement',
    Subscription = 'Subscription',
    Usage = 'Usage',
    Error = 'Error',
    Expansion = 'Expansion',
}

export enum AMPLITUDE_EVENTS {
    SELECT_CARD_TEMPLATE = 'select card template',
    SETUP_CARD = 'setup card',
    CUSTOMIZE_CARD = 'customize card design',
    LAUNCH_CARD = 'launch card',
    DOWNLOAD_CARD_QR_CODE = 'download card qr code',
    BULK_CREATE_CARD = 'bulk create card',
    DOWNLOAD_WALLET_PASS = 'download wallet pass',
    BULK_INVITE_USERS = 'bulk invite users',
    TRIAL_START = 'start trial',
    FORMS_ADD_QUESTION = 'forms add question',
    FORMS_SELECT_THEME = 'forms select theme',
    FORMS_ENABLE_SELF_NOTIFICATION = 'forms enable self notification',
    FORMS_ENABLE_EMAIL_NOTIFICATION = 'forms enable email notification',
    FORMS_ENABLE_RETARGETING = 'forms enable retargeting',
    FORMS_SELECT_LANGUAGE = 'forms select language',
    FORMS_CREATED = 'forms created',
    QR_FILTERS_COMBINATION = 'QR Filters Combination',
    QR_FILTERS = 'QR Filters',
    QR_FILTER_NO_RESULTS = 'QR Filter No Results',
    QR_VIEW = 'QR Listing View',
    SETUP_TEMPLATE_CARD = 'setup template card',
    CUSTOMIZE_TEMPLATE_CARD = 'customize template card',
    LAUNCH_TEMPLATE_CARD = 'launch template card',
    SELECT_NEW_CARD_TEMPLATE = 'select new card template',
    SHARE_CARD = 'share card',
    EXPORT_QR_CODES = 'Export QR Codes',
    SEARCH_SEE_MORE = 'GlobalSearch_Seemore',
    SEARCH_SEE_ALL_RELATED_ARTCILES = 'GlobalSearch_Helpdocs',
    SEARCH_ON_FILTERS = 'GlobalSearch_Filters',
    SEARCH_ON_SORTS = 'GlobalSearch_Sort',
    SEARCH_CLICKS_ON_SEARCH_ICON = 'GlobalSearch_Clicks',
    QR_COPY_TO_CLIPBOARD = 'QR_Finalstep_CopytoClipboard',
    TOOLBAR_OPTIONS = 'toolbar options',
    DBC_LIST_FILTER = 'dbc list filter',
    EXPORT_CONTACTS = 'export contacts',
    ANALYTICS_PAGE_SCROLL_DBC = 'analytics page scrolled for dbc',
    SETTING_CUSTOM_FONTS = 'setting-custom-fonts',
    QR_PAGE_VIEW_ANALYTICS_CTA = 'QRpage_ViewAnalytics_CTA',
    ANALYTICS_FILTERS_QR = 'Analytics Filters QR',
    ANALYTICS_FILTERS_DBC = 'Analytics Filters DBC',
    LINKPAGE_DUPLICATE = 'Duplicate_Linkpage',
    LINKPAGE_TRANSFER = 'Org_transfer_linkpage',
    LINKPAGE_COPY = 'Org_Copy_linkpage',
    FORM_TRANSFER = 'Org_transfer_forms',
    FORM_COPY = 'Org_Copy_forms',
    DIRECT_PREVIEW = 'direct_preview',
    DBC_WEEKLY_ANALYTICS = 'dbc-weekly-analytics',
    ANALYTICS_SHARE = 'Analytics_Share',
    ANALYTICS_SENT = 'Analytics_Sent',
    ADD_CUSTOM_DOMAIN_CTA = 'AddCustomDomain_CTA',
    AUTOMATIC_QR_SAVED = 'AutomaticQR_saved',
    AUTOMATIC_QR_CLICKS = 'AutomaticQR_clicks',
    ADDONS = 'addons',
    CANCELLATION_CLAIMED_OFFER = 'Cancellation_claimedoffer',
    FOMO_KEEP_SUBSCRIPTION_CLICKS = 'FOMO_KeepSubscription_clicks',
    CANCEL_SUBSCRIPTION_CLICKS = 'CancelSubscription_clicks',
    DOWNLOAD_QR_CODE_OR_COPY_TO_CLIPBOARD = 'Download_Qr_Code_Or_Copy_To_Clipboard',
    CONTACT_SALES = 'Contact sales',
    CANCELLATION_INITIATED_DBC = 'cancellation_initiated_dbc',
    CANCELLATION_FEEDBACK_DBC = 'cancellation_feedback_submitted_dbc',
    CANCEL_CLAIMEDOFFER_DBC = 'cancel_claimedoffer_dbc',
    CANCELLED_SUBSCRIPTION_DBC = 'cancelled_subscription_dbc',
    VIEW_DASHBOARD = 'view_dashboard',
    ADVANCED_CUSTOMIZATION = 'adv_customization_upsell_modal_opened',
    PASSWORD_PROTECTION = 'Password_protect_upsell_CTA',
    AGE_RESTRICTION = 'Age_restrict_upsell_CTA',
    PASSWORD_PROTECTION_USAGE = 'password_protection_click',
    AGE_RESTRICTION_USAGE = 'age_restriction_click',
    LOCATION_TRACKING_USAGE = 'Toggle_GPS_tracking',
    QR_TEMPLATE_LIST = 'qr_templates_listing_view',
    QR_TEMPLATE_CREATE = 'create_QR_template_CTA',
    LANDING_PAGE_LIST = 'landingpage_listing_view',
    CREATE_LANDING_PAGE = 'create_landingpage_CTA',
    EDIT_LANDING_PAGE = 'edit_landingpage_CTA',
    SAVE_LANDING_PAGE = 'save_landingpage_CTA',
    SELECT_LANDING_PAGE_TEMPLATE = 'select_landingpage_template',
    QR_NOTES = 'QR_Notes',
    CREATE_QR_CODE_SIDENAV_CLICKS= 'create_qr_code_sidenav_clicks',

    // Overview page events
    OVERVIEW_CARD_CLICKS = 'Overview_card_clicks',
    OVERVIEW_CREATE = 'Overview_Create',
    OVERVIEW_NAV_CLICKS = 'Overview_nav_clicks',

    INVITE_TEAMMATES_CLICKS = 'InviteTeammates_Clicks',
    INVITE_TEAMMATES_UPGRADES = 'InviteTeammates_Upgrades',
    ANALYTICS_BANNER_CTA = 'AnalyticsBanner_CTA',
    CSV_EXPORT_UPGRADE = 'CSVExport_Upgrade',
    CHART_UPGRADE = 'Chart_Upgrade',
    DATEPICKER_UPGRADE = 'Datepicker_Upgrade',
    KNOW_MORE_DISCOUNT = 'spu_banner_click',
    SPU_BANNER_CLOSE = 'spu_banner_close',

    // QR watchlist events
    WATCHLIST_TAB_CLICK = 'Watchlist_tab_clicks',
    ADD_TO_WATCHLIST_CLICK = 'AddtoWatchlist_Clicks',
    REMOVE_FROM_WATCHLIST = 'Remove_from_Watchlist',
    ADD_TO_WATCHLIST = 'Watchlist_QRCount',
    REMOVE_FROM_WATCHLIST_CLICK = 'RemoveWatchlist_Clicks',

    LINKPAGE_AD_MODAL_CLICK = 'Linkpage_Ad_Modal_click',
    CREATE_FORM_QR_CTA = 'Create Form CTA',
    SELECT_FORM_TEMPLATE = 'Select form template',
    LINKPAGE_AD_MODAL_VIEW = 'Linkpage_Ad_Modal_View',
    EXPLORE_QR_CODE_TYPES = 'Explore QR Code Types',
    GO_MOBILE_MODAL_OPENED = 'go_mobile_modal_openend',
    MYCARDS_LANDING_PAGE_OPENED_DESKTOP_POPUP = 'mycards_landing_page_opened_desktop_popup',
    MYCARDS_NUDGE_CLICKED_MOBILE = 'mycards_nudge_clicked_mobile',
    QR_CODES_IN_DROPDOWN_OF_SIDENAV = 'qr codes in dropdown of sidenav',
    UNIQODE_LOGO_TOP_NAV = 'uniqode logo top nav',
    CREATE_QR_CODE_CTA_LISTING = 'create qr code cta listing',
    CREATE_QR_CODE_CTA_CENTRE_LISTING = 'create qr code cta centre listing'

}

@Injectable()
export class AmplitudeService {

    constructor(private authService: AuthService) {

        this.authService.isLoggedIn$.subscribe(loggedIn => {
            if (loggedIn) {
                amplitude.getInstance().init(environment.amplitudeAPIKey, this.authService.getUser().id);
                this.setUserJoiningDate();
            } else {
                amplitude.getInstance().setUserId(null);
                amplitude.getInstance().regenerateDeviceId();
            }
        });
    }

    private setUserJoiningDate() {
        const identity = new amplitude.Identify();
        identity.setOnce('created_at', this.authService.getUser().date_joined.toISOString());
        amplitude.getInstance().identify(identity);
    }

    /**
   * @param category @AMPLITUDE_EVENT_CATEGORIES
   * @param event name of the event
   * @param eventProperties JSON object with properties to be tracked.
   */
    logEvent(category: AMPLITUDE_EVENT_CATEGORIES, event: string, eventProperties: object = null) {
        if (!category) {
            throw new Error('category is required');
        }
        amplitude.getInstance().logEvent(event, eventProperties);
    }

    /**
   * @param properties JSON object with properties to be set.
   */
    setUserProperties(properties: object) {
        const identity = new amplitude.Identify();
        for (const property in properties) {
            identity.set(property, properties[property]);
        }
        amplitude.getInstance().identify(identity);
    }

}
