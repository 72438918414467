export const environment = {
    production: true,
    envName: 'production',
    appVersion: '6.0',

    /* API constants */
    baseURL: 'https://appserver.beaconstac.com/',
    reportingBaseURL: 'https://reporting.beaconstac.com/',
    apiBaseURL: 'https://api.beaconstac.com/',
    apiEndpoint: 'api/',
    reportingEndpoint: 'reporting/',
    apiVersion: '2.0',
    reportingVersion: '2.0',
    dbcReportingVersion: '3.0',
    qrV3ReportingVersion: '3.0',
    linkpageReportingVersion: '3.0',
    formReportingVersion: '3.0',
    eddyProUrl: 'https://eddy.pro/markdowncard/',
    eddyProPDFUrl: 'https://eddy.pro/pdf/',
    qrUrl: 'https://qr.tapnscan.me/',
    qrDomains: ['https://qr.tapnscan.me', 'https://qrcodes.pro'],
    formUrl: 'https://eddy.pro/form/',
    formUrlV2: 'https://eddy.pro/form/v2/',
    linkpageDomain: 'linkpages.pro',
    dbcDomain: 'smartcards.pro',
    appleWalletUrl: 'https://eddy.pro/passwallet/',
    googleWalletUrl: 'https://eddy.pro/google-passwallet/',
    dbcAppleWalletUrl: 'https://smartcards.pro/apple-passwallet/',
    dbcGoogleWalletUrl: 'https://smartcards.pro/google-passwallet/',

    themeImageEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/images/markdown_templates/',
    themeCssEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/css/markdown_template_css/',
    themeScriptEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/js/markdown_templates/',

    formTemplateDataEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/form_templates/form-template-data.json?v=1.2',
    formTemplateLanguageEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/form_templates/form-language-data.json',
    bformTemplateDataEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/form_templates/bform-template-data.json?v=1.3',

    vCardPlusCssEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/css/vcard_template_css/',
    multiLanguageDropdownCssEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/static/css/language_dropdown_css/',
    imagesEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/images/',
    gifEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/gifs/',

    facebookCssEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/css/facebook_like_template_css/',
    socialMediaCssEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/css/social_media_template_css/',
    couponCodeCssEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/css/coupon_template_css/coupon.css',
    businessCardCssEndpoint: 'https://d3nvy39jvu7woe.cloudfront.net/' + 'static/css/business_card_template_css/business_card.css',


    /* Paywhirl Widget keys  */
    paywhirlkey: 'pw_59bbc1ff62ffd',
    domain: 'mobstac-9a8d',
    uuid: '12a01f93-950f-4c2a-bab9-632831449acd',

    // Froala editor
    froalaKey: 'iTB2xB5C1D4D4C3C1wd1DBKSPF1WKTUCQOa1OURPJ1KDe2F-11D2C2D2H2C3A3C2D6D1C2==',

    intercomAppId: 'hc4as48h',
    mixpanelKey: '9e3f81f43f305d7fdea04c625e05608d',

    stripeKey: 'pk_WKgdkQCyGFmsrbo2ECwWE7HrxmMhm',

    launchDarklyClientID: '668bd57ffecbdd10119b5268',

    firebaseApiKey: 'AIzaSyCQGIslMafcbncF05U-XDf_UuPOmkOhOws',
    firebaseProject: 'bac-prod',

    MAIN_PLAN_IDS: [5870, 26077, 5872, 26078, 25055, 34912, 43335, 45559],
    ADD_ON_PLANS: [26949],

    // Uniqode store url
    storeUrl: 'https://www.uniqode.com/store/login',
    buyQrUrl: 'https://www.uniqode.com/buy-qr-code-solution/',
    unifiedStoreUrl: 'https://www.uniqode.com/buy-proximity-marketing-solution/',

    // Auth0
    authOClientId: 'XivQaZxjslS3PB47HNQp8TOUm3gJIB8Q',
    auth0Domain: 'auth.beaconstac.com',
    auth0URN: 'urn:auth0:beaconstac',
    mfaAudienceURL: 'https://beaconstac.auth0.com/mfa/',

    // Google Client OAuth 2.0
    oAuthClientId: '627263713447-4hpv7et7gc6nq9ikqckb8bbnacsvscn0',

    // Custom domain configuration
    customDomainURL: 'custom-mn25ymg2qt-8.tapnscan.me',

    // Amplitude
    amplitudeAPIKey: 'ad99af175f39d509b340cb95fab60c35',

    // SCIM QRCode URL
    scimQRCodeURL: 'https://active-directory.beaconstac.com/scim/',

    // SCIM DBC URL
    scimDBCURL: 'https://active-directory.beaconstac.com/dbc/scim/',

    // Linkedin Conversion Tracking
    linkedinConversionTrackingTrialUpgrade: 11713228,
    linkedinConversionTrackingStartTrial: 5171828,

    // Google Static Map
    staticMapApiKey: 'AIzaSyD0ZXqqpeNriR-lwfD4kWEuZ_MKYR2vZO0',
    staticMapBaseURL: 'https://maps.googleapis.com/maps/api/staticmap',
    BFORM_DEFAULT_THEME_ID: 22,
    MyCardsPwaUrl: 'https://mycards.uniqode.com'
};


export enum PLAN_IDS {
    Basic = 'plan_SFT-BASIC-MNTH',
    Premium = 'plan_SFT-PREMIUM-MNTH',
    Reseller = 'plan_SFT-RESELLER-MNTH',
    WhiteLabel = 'plan_SFT-WHITELABEL-MNTH'
}
