import {BehaviorSubject, forkJoin, Observable, Subject, throwError as observableThrowError} from 'rxjs';

import {catchError, distinctUntilChanged, filter, finalize, mapTo, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {Organization, ReducedOrganization} from 'app/user-account/user-organization.model';
import {SUBSCRIPTION_STATE, User} from '../user-account/user.model';
import {CurrentPageStat} from 'app/shared/current-page-stat.model';
import {BEACONSTAC_HOSTS} from 'app/app.constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import * as moment from 'moment'
import {FirebaseService} from './firebase.service';
import {SsoAuthService} from './sso-auth.service';
import {datadogRum} from '@datadog/browser-rum';
import {DASHBOARD_MENU, isUniqodeDomainRequest, PRODUCT_TYPES} from '../shared/utils';
import {DashboardService} from './dashboard.service';
import { isBoolean } from 'lodash';

import * as LDClient from 'launchdarkly-js-client-sdk';

declare const satismeter: any;
declare const gtag: any;

export enum DATADOG_ACTIONS {
    CREATE_DBC = 'create DBC',
    CREATE_DBC_FAILED = 'create DBC failed',
    DOWNLOAD_APPLE_WALLET_PASS_DBC = 'download apple wallet pass dbc',
    DOWNLOAD_GOOGLE_WALLET_PASS_DBC = 'download google wallet pass dbc',
    CREATE_DBC_TEMPLATE = 'create DBC template',
    CREATE_DBC_TEMPLATE_FAILED = 'create DBC template failed',
}

@Injectable()
export class AuthService {

    private static get authUrl(){
        let requestURL = environment.baseURL + environment.apiEndpoint + environment.apiVersion;

        if (isUniqodeDomainRequest()) {
            requestURL  = requestURL.replace(environment.baseURL, environment.apiBaseURL);
        }
        return requestURL;
    }

    private static storageKeys = {
        token: 'BSAuthToken',
        tokenType: 'BSAuthTokenType',
        user: 'BSUserObj',
        isSafeSuperUser: 'BSIsSafeSuperUser',
        currentOrgObj: 'BSCurrentOrgObj',
        productType: 'ProductType'
    };
    private static cookieKeys = {
        session: 'BSSessionToken'
    };

    static tokeTypes = {
        token: 'Token',
        bearer: 'Bearer'
    };

    private tokenSubject = new BehaviorSubject<string>(null);
    private tokenTypeSubject = new BehaviorSubject<string>(null);
    private userSubject = new BehaviorSubject<User>(null);
    private isSafeSuperUserSubject = new BehaviorSubject<boolean>(null);
    private currentOrgIdSubject = new BehaviorSubject<number>(null);
    private currentPageSubject = new BehaviorSubject<CurrentPageStat>(null);
    private organizationsListSubject = new BehaviorSubject<Organization[]>(null);
    private currentOrgCreatedDate = new BehaviorSubject<any>(null);
    private LDClient: LDClient.LDClient = null;

    public isLoggedInSubject = new BehaviorSubject<boolean>(false);
    public isLoggedIn$ = this.isLoggedInSubject.pipe(distinctUntilChanged());
    public user$ = this.userSubject.pipe(distinctUntilChanged());
    public organizationsList$ = this.organizationsListSubject.asObservable();
    public isSafeSuperUser$ = this.isSafeSuperUserSubject.asObservable();
    public currentOrgId$ = this.currentOrgIdSubject.pipe(distinctUntilChanged());
    public currentPage$ = this.currentPageSubject.pipe(distinctUntilChanged());
    public currentOrgCreatedDate$ = this.currentOrgCreatedDate.pipe(distinctUntilChanged());

    // Auth0 Observables
    public isAuthenticated$;
    public authError$: any;
    public postAuthtargetRoute$;

    private dataUpdateSubject = new BehaviorSubject<null>(null);
    private dataUpdate$ = this.dataUpdateSubject.asObservable();

    hasUnsavedChangesSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    hasUnsavedChanges$ = this.hasUnsavedChangesSource.pipe();

    // Special subject set it true to allow redirection without showing discard-change-modal
    allowRedirectForcefully: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    // Local Storage Changes Subject for multiple inter tab communication
    private storageChangeSubject: Subject<StorageEvent> = new Subject<StorageEvent>();
    storageChange$: Observable<StorageEvent> = this.storageChangeSubject.pipe();

    private static clearLocalStorage() {
        for (const key in AuthService.storageKeys) {
            localStorage.removeItem(AuthService.storageKeys[key]);
        }
    }

    private static getNewSessionId(): string {
        let id = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$!';

        for (let i = 0; i < 20; i++) {
            id += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return id;
    }

    reAuthenticateUser(): void {
        this.router.navigate(['/login/verify']);
    }

    constructor(private http: HttpClient, private router: Router,
        private cookieService: CookieService,
        private firebaseService: FirebaseService,
        private dashboardService: DashboardService,
        private ssoService: SsoAuthService) {
        this.initializeMonitoring();
        this.user$.subscribe(changedUser => {
            try {
                const isSafeSuperUser = localStorage.getItem(AuthService.storageKeys.isSafeSuperUser);
                if (isSafeSuperUser) {
                    this.setSafeSuperUser(JSON.parse(isSafeSuperUser))
                }
            } catch (e) {
            }
            if (changedUser && this.checkSubscriptionState(changedUser) && !this.isSafeSuperUser()) {
                const urlQueryParams = new URLSearchParams(window.location.search);
                const queries = {
                    source: 'expiredplan-page-upgrade-cta'
                }
                for (const param of urlQueryParams) {
                    queries[param[0]] = param[1]
                }
                this.router.navigate(['/renew-plan'], {queryParams: queries});
            }
        });
        this.isAuthenticated$ = this.ssoService.isAuthenticatedUser$;
        this.authError$ = this.ssoService.error$;
        this.postAuthtargetRoute$ = this.ssoService.targetRoute$;

        this.isAuthenticated$.pipe(
            distinctUntilChanged(),
            filter(isAuthenticated => isAuthenticated === true)
        ).subscribe(() => {
            this.ssoService.getToken$().subscribe(authToken => {
                try {
                    this.tokenSubject.next(authToken);
                    this.tokenTypeSubject.next(AuthService.tokeTypes.bearer);
                    this.fetchOrganizationAndUser().subscribe(() => {}, (err) => {
                        this.router.navigate(['/sso']);
                        this.ssoService.errorSubject$.next({message: 'No user account found. Please contact <a href="mailto:support@uniqode.com">support@uniqode.com</a>.'});
                    });
                } catch (e) {
                    console.error(e);
                }
            })
        });
        this.checkAndUseBasicAuth();

        window.addEventListener('storage', this.handleStorageChange.bind(this));
    }

    setDefaultUserOrg() {
        const user = JSON.parse(localStorage.getItem(AuthService.storageKeys.user));
        if (user) {
            this.setCurrentOrgObjInLocalStorage(user.organization);
        }
    }

    updateOrg() {
        const org = JSON.parse(localStorage.getItem(AuthService.storageKeys.currentOrgObj));
        if (org) {
            this.setCurrentOrgObjInLocalStorage(org);
        }
    }

    fetchOrganizationByID(orgID: number): Observable<any> {
        const argsObject = {};
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.getTokenType()} ${this.getToken()}`
        });
        argsObject['headers'] = headers;
        return this.http.get(AuthService.authUrl + `/myorganizations/${orgID}/?organization=${orgID}`, argsObject).pipe(
            catchError((response: any): Observable<any> => {
                return observableThrowError(response);
            }));
    }

    fetchOrganizationAndUser() {
        return forkJoin(this.fetchOrganization(), this.fetchUser()).pipe(tap((res) => {
            this.setOrganizationsList(res[0]['results'])
            const user = new User(res[1]);
            this.updateOrg();
            this.setUser(user);
            this.setupExternalServices();
            this.isLoggedInSubject.next(true);
        }))
    }

    checkAndUseBasicAuth(): void {
        try {
            const token = localStorage.getItem(AuthService.storageKeys.token);
            const user = JSON.parse(localStorage.getItem(AuthService.storageKeys.user));

            if (token && user) {
                this.setOrganizationsList([user.organization])
                this.tokenSubject.next(token);
                this.tokenTypeSubject.next(AuthService.tokeTypes.token);
                this.isLoggedInSubject.next(true);
                this.updateOrg();
                this.setUser(new User(user));
                this.initializeTracking();
                this.setUpNotificationService();
            }
        } catch (e) {
            console.error(e);
        }
    }

    initializeMonitoring() {
        datadogRum.init({
            applicationId: '2651125c-e38a-4531-a59f-d91282c61d2a',
            clientToken: 'pub56247d9db7da4ca05935a36b40e2169a',
            site: 'datadoghq.com',
            service: 'beaconstac-dashboard',
            env: environment.envName,
            sampleRate: 100,
            trackInteractions: true,
            defaultPrivacyLevel: 'mask-user-input'
        });

        datadogRum.startSessionReplayRecording();
    }

    setUpDataDogUser() {
        const user = this.getUser();
        datadogRum.setUser({
            id: user.id.toString()
        });
        this.setDatadogUserProperties(user.meta.industry, user.meta.company_size)

    }


    fetchUser(): Observable<any> {
        const argsObject = {};
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.getTokenType()} ${this.getToken()}`
        });
        argsObject['headers'] = headers;
        return this.http.get(AuthService.authUrl + '/users/me/', argsObject).pipe(
            catchError((response: any): Observable<any> => {
                const error = {
                    status: response.status,
                    message: 'Login failed',
                    multiAuthToken: null,
                    user: null
                };
                try {
                    switch (response.status) {
                        case 400:
                            error.message = 'Incorrect credentials';
                            break;
                        case 401:
                            error.message = response.error.detail;
                            break;
                        case 402:
                            const json = response.error;
                            const user = new User(json.user);
                            error.user = user;
                            try {
                                localStorage.setItem(AuthService.storageKeys.user, user.toString());
                            } catch (ex) {
                                console.log(ex);
                            }
                            error.message = 'Your access has expired. Please contact the owner of this website';
                            error.multiAuthToken = response['multi_auth_token'];
                            break;
                    }
                } catch (ex) {
                    console.error(ex);
                    error.message = 'Login failed';
                }
                return observableThrowError(error);
            }));
    }

    auth0Login(redirectPath?: string): void {
        this.ssoService.login(redirectPath);
    }

    login(username: string, password: string, autoLogout = true): Observable<boolean> {
        if (autoLogout) {
            this.logout();
        }

        const body = {
            username: username,
            password: password,
            appVersion: 2.0
        };

        return this.http.post(AuthService.authUrl + '/api-auth-token/', body).pipe(
            tap(async (response: any) => { // TODO check expiry
                const json = response;
                if (!json.token) {
                    return false;
                }
                this.setUserProperties(json);
                await this.fetchOrganizationAndUser().pipe(catchError((res): Observable<any> => {
                    if (autoLogout) {
                        this.logout();
                    }
                    const err = {
                        status: res.status,
                        message: 'Login failed',
                        multiAuthToken: null,
                        user: null
                    };
                    try {
                        switch (res.status) {
                            case 400:
                                err.message = 'Incorrect credentials';
                                break;
                            case 401:
                                err.message = res.error.detail;
                                break;
                            case 402:
                                const auth_error_json = res.error;
                                const user = new User(auth_error_json.user);
                                err.user = user;
                                try {
                                    localStorage.setItem(AuthService.storageKeys.user, user.toString());
                                } catch (ex) {
                                    console.log(ex);
                                }
                                err.message = 'Your access has expired. Please contact the owner of this website';
                                err.multiAuthToken = res['multi_auth_token'];
                                break;
                        }
                    } catch (ex) {
                        console.error(ex);
                        err.message = 'Login failed';
                    }
                    return observableThrowError(err);
                })).subscribe(res => {
                    this.setupExternalServices();
                    this.isLoggedInSubject.next(true);
                }, () => {
                })
                return true;
            }));

    }

    setUserProperties(json: any) {
        this.setSafeSuperUser(json.is_safe_super_user);
        this.tokenSubject.next(json.token);
        try {
            localStorage.setItem(AuthService.storageKeys.token, json.token);
            localStorage.setItem(AuthService.storageKeys.tokenType, AuthService.tokeTypes.token);
            this.tokenTypeSubject.next(AuthService.tokeTypes.token)
        } catch (ex) {
            console.log(ex);
        }
    }

    setupExternalServices() {
        this.initializeLaunchDarkly();
        this.initializeTracking();
        this.setUpdateUserSession();
        this.setUpNotificationService();
        this.setUpDataDogUser();
        this.setUpGoogleAnalytics();
    }

    logout() {
        AuthService.clearLocalStorage();
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.getTokenType()} ${this.getToken()}`
        });
        if (this.isSafeSuperUser()) {
            return this.http.post(AuthService.authUrl + '/users/staff_user_logout/', null,
                {headers: headers}).pipe(
                mapTo(true),
                catchError((error) => {
                    console.error(error);
                    return observableThrowError('Logout failed');
                })).subscribe(res => {
                this.completeLogoutProcess()
            });
        } else {
            this.http.post(AuthService.authUrl + '/users/logout/', null,
                {headers: headers}).pipe(
                mapTo(true),
                catchError((error) => {
                    console.error(error);
                    return observableThrowError('Logout failed');
                }),
                finalize(() => {
                    this.completeLogoutProcess()
                })
            ).subscribe();
        }
    }

    completeLogoutProcess() {
        const logoutURL = this.getUser()?.organization.meta['logout_url'];
        this.setSafeSuperUser(null);
        this.setUser(null);
        this.setCurrentOrgObjInLocalStorage(null);
        this.tokenSubject.next(null);
        this.isLoggedInSubject.next(false);
        this.clearUserSession();

        this.firebaseService.logout();
        if (this.isWhiteLabelDashboard()) {
            this.router.navigate(['/login']);
        } else {
            this.ssoService.logout(logoutURL);
            this.router.navigate(['/logout']);
        }
    }


    sendResetPasswordMail(username: string): Observable<boolean> {
        const body = {
            email: username
        };
        return this.http
            .post(AuthService.authUrl + '/users/password_reset/', body).pipe(
                mapTo(true),
                catchError((response: any): Observable<any> => {
                    console.error(response);
                    return observableThrowError('Reset failed');
                }));
    }

    resetPassword(token: string, uidb36: string, new1: string, new2: string): Observable<boolean> {
        const body = {
            new_password1: new1,
            new_password2: new2
        };
        return this.http
            .post(AuthService.authUrl + '/users/password_reset/' + uidb36 + '-' + token + '/', body).pipe(
                mapTo(true),
                catchError(response => {
                    console.error(response);
                    return observableThrowError(response);
                }));
    }

    confirmChildInvitation(token: string, uidb36: string, source_cta: string, first_name: string, last_name: string, new1: string, new2: string): Observable<boolean> {
        const body = {
            first_name: first_name,
            last_name: last_name,
            new_password1: new1,
            new_password2: new2
        };
        return this.http
            .post(AuthService.authUrl + '/users/join/' + uidb36 + '-' + token + '/?source=' + source_cta, body).pipe(
                tap(async (response: any) => {
                    const json = response;
                    if (!json.token) {
                        return false;
                    }
                    this.setUserProperties(json);
                    await this.fetchOrganizationAndUser().pipe(catchError(error => {
                        console.error(error);
                        return observableThrowError(error);
                    })).subscribe(res => {
                    })
                    return true;
                }));
    }

    unsubscribeChurnEmail(token: string, uidb36: string, source_cta: string): Observable<boolean> {
        return this.http
            .post(AuthService.authUrl + '/users/churn-email-unsubscribe/' + uidb36 + '-' + token + '/?source=' + source_cta, {}).pipe(
                mapTo(true),
                catchError(response => {
                    console.error(response);
                    return observableThrowError(response);
                }));
    }

    changePassword(old: string, new1: string, new2: string): Observable<boolean> {
        if (!this.isLoggedIn()) {
            this.logout();
            return observableThrowError('You have been logged out');
        }

        const body = {
            old_password: old,
            new_password1: new1,
            new_password2: new2
        };
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.getTokenType()} ${this.getToken()}`
        });

        return this.http.post(AuthService.authUrl + '/users/' + this.getUser().id.toString() + '/password_change/', body,
            {headers: headers}).pipe(
            mapTo(true),
            catchError((error) => {
                console.error(error);
                const detail = error['error']['detail']['old_password'] || 'Password change failed';
                return observableThrowError(detail);
            }));
    }

    isLoggedIn() {
        return this.tokenSubject.getValue() !== null;
    }

    setUser(user: User) {
        this.user$.pipe(take(1)).subscribe(res => {
            if (JSON.stringify(user) === JSON.stringify(res)) {
                return;
            }
            this.userSubject.next(user);
            if (user) {
                try {
                    localStorage.setItem(AuthService.storageKeys.user, user.toString());
                } catch (ex) {
                    console.log(ex);
                }
                if (!this.getCurrentOrganization().id) {
                    this.setCurrentOrgObjInLocalStorage(user.organization);
                }
            } else {
                try {
                    localStorage.removeItem(AuthService.storageKeys.user);
                } catch (ex) {
                    console.log(ex);
                }
            }
        })
        this.setupCurrentProduct(user);
    }

    setOrganization(org: Organization) {
        const user = this.getUser();
        if (!user) {
            return;
        }
        user.organization = org;
        this.setUser(user);
    }

    setCurrentOrgObjInLocalStorage(organization: Organization) {
        if (organization) {
            try {
                localStorage.setItem(AuthService.storageKeys.currentOrgObj, JSON.stringify(organization));
                this.currentOrgIdSubject.next(organization.id);
                this.currentOrgCreatedDate.next(organization.created);
            } catch (ex) {
                console.log(ex);
            }
        } else {
            try {
                localStorage.removeItem(AuthService.storageKeys.currentOrgObj);
                this.currentOrgIdSubject.next(null);
                this.currentOrgCreatedDate.next(null);
            } catch (ex) {
                console.log(ex);
            }
        }
    }

    getOrgByID(id: number): Organization {
        return this.organizationsListSubject.getValue()?.filter(res => res.id === id)[0];
    }

    setCurrentPage(pageStat: CurrentPageStat) {
        this.currentPageSubject.next(pageStat);
    }

    getCurrentPage(): CurrentPageStat {
        return this.currentPageSubject.getValue();
    }

    getToken() {
        return this.tokenSubject.getValue();
    }

    getTokenType() {
        return this.tokenTypeSubject.getValue();
    }

    getUser() {
        return this.userSubject.getValue();
    }

    getOrganization() {
        return this.getUser().isSuperAdmin() ? this.getUser().organization : this.getCurrentOrganization()
    }

    setOrganizationsList(res) {
        const orgList = []
        for (const org of res) {
            orgList.push(new Organization(org))
        }
        this.organizationsListSubject.next(orgList)
    }

    getOrganizationsList() {
        return this.organizationsListSubject.getValue().map(org => {
            return {
                id: org.id,
                name: org.name,
                master: !org.parent,
            }
        })
    }

    getCurrentOrgId() {
        return this.currentOrgIdSubject.getValue();
    }

    getCurrentOrgCreatedDate() {
        return this.currentOrgCreatedDate.getValue();
    }

    getCurrentOrganization(): Organization {
        return new Organization(JSON.parse(localStorage.getItem(AuthService.storageKeys.currentOrgObj)));
    }

    getCurrentReducedOrganization(): ReducedOrganization {
        const organization = this.getCurrentOrganization()
        return {
            id: organization.id,
            name: organization.name,
            master: !organization.parent,
        }
    }

    private initializeLaunchDarkly() {
        const context: LDClient.LDContext = {
            kind: 'user',
            key: this.getUser().id.toString(),
            custom: {
                plan: this.getUser().getReadablePlan(),
                trial: this.getUser().isOnTrialPlan(PRODUCT_TYPES.QR),
                dbcPlan: this.getUser().getReadablePlan(PRODUCT_TYPES.DBC),
                dbcTrial: this.getUser().isOnTrialPlan(PRODUCT_TYPES.DBC),
                group: this.getUser().getReadableGroup(),
                industry: this.getUser().meta.industry,
                companySize: this.getUser().meta.company_size,
                organizationId: this.getCurrentOrganization().id.toString(),
                organizationName: this.getCurrentOrganization().name,
                userEmailDomain: this.getUser().email.split('@')[1]
            }
        };
        this.LDClient = LDClient.initialize(environment.launchDarklyClientID, context);
    }

    public async getFeatureFlag(flagKey: string, defaultValue: boolean | string | number) {
        try {
            await this.LDClient.waitForInitialization(5);
            return  this.LDClient.variation(flagKey, false);
        } catch (e) {
            console.error(e);
            return defaultValue;
        }
    }

    public async launchDarklyAddCustomMetrics(key: string, data: any, unit?: number) {
        try {
            await this.LDClient.waitForInitialization(5);
            if (unit) {
                this.LDClient.track(key, data, unit);
            } else {
                this.LDClient.track(key, data);
            }
        } catch (e) {
            console.error(e);
        }
    }

    private initializeTracking(): void {
        const user: User = this.getUser();
        if (!user) {
            return;
        }

        const satismeterKey = user.isOwner() ? 'lQSmOCGncPd1af6s' : '6rMy6OtUx2PZrJkO';
        satismeter({
            writeKey: satismeterKey,
            userId: user.id,
            traits: {
                name: user.first_name + ' ' + user.last_name,
                email: user.username,
                createdAt: new Date(user.date_joined).toDateString(),
                plan: user.getReadablePlan(),
                group: user.getReadableGroup(),
                industry: user.meta.industry,
                companySize: user.meta.company_size
            }
        });
    }

    setSatisMeterIndustryAttributes(industry: string, companySize: string) {
        const user = this.getUser();
        const satismeterKey = user.isOwner() ? 'lQSmOCGncPd1af6s' : '6rMy6OtUx2PZrJkO';
        satismeter({
            writeKey: satismeterKey,
            userId: user.id,
            traits: {
                industry: industry,
                companySize: companySize
            }
        });
    }

    // Returns if the dashboard is white labeled or not
    isWhiteLabelDashboard() {
        const host = window.location.hostname.toLowerCase();
        return BEACONSTAC_HOSTS.indexOf(host) === -1 && host.indexOf('beaconstac.com') === -1 && host.indexOf('uniqode.com') === -1;
    }

    setUpdateUserSession(): void {
        if (this.hasUserSessionExpired()) {
            const expires = moment().add(1, 'days');
            this.cookieService.set(AuthService.cookieKeys.session, AuthService.getNewSessionId(), new Date(Number.parseInt(expires.format('x'))));
        }
    }

    clearUserSession(): void {
        this.cookieService.deleteAll(AuthService.cookieKeys.session);
        this.cookieService.deleteAll('domain');
    }

    hasUserSessionExpired(): boolean {
        return !this.cookieService.get(AuthService.cookieKeys.session);
    }

    isReAuthenticationRequired(): boolean {
        return false;
    }


    setUpGoogleAnalytics(): void {
        if (environment.production) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    gtag('config', 'G-WEQ8CN3L9M', {
                        'page_path': event.urlAfterRedirects
                    });
                }
            });
        }
    }

    setDatadogUserProperties(industry?: string, companySize?: string) {
        const plan = this.getUser().getReadablePlan();
        const dbcPlan = this.getUser().getReadablePlan(PRODUCT_TYPES.DBC);
        const trial = this.getUser().isOnTrialPlan(PRODUCT_TYPES.QR);
        const dbcTrial = this.getUser().isOnTrialPlan(PRODUCT_TYPES.DBC);
        const group = this.getUser().getReadableGroup();

        if (plan) {
            datadogRum.setUserProperty('plan', plan);
        }
        if (dbcPlan) {
            datadogRum.setUserProperty('dbcPlan', dbcPlan);
        }
        if (industry) {
            datadogRum.setUserProperty('industry', industry);
        }
        if (companySize) {
            datadogRum.setUserProperty('companySize', companySize);
        }
        if (isBoolean(trial)) {
            datadogRum.setUserProperty('trial', trial);
        }
        if (isBoolean(dbcTrial)) {
            datadogRum.setUserProperty('dbcTrial', dbcTrial);
        }
        if (group) {
            datadogRum.setUserProperty('group', group);
        }
    }

    addDatadogAction(eventName: string, eventProperties: any = {}): void {
        datadogRum.addAction(eventName, eventProperties);
    }

    setDataUpdate() {
        this.dataUpdateSubject.next(null);
    }


    get dataUpdate() {
        return this.dataUpdate$;
    }

    setUpNotificationService() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.getTokenType()} ${this.getToken()}`
        });

        this.http.get(AuthService.authUrl + '/users/firebase_token/',
            {headers: headers}).subscribe((tokenRes: { firebase_token: string }) => {
            if (!tokenRes.firebase_token) {
                return;
            }
            this.user$.subscribe(() => {
                this.firebaseService.login(tokenRes.firebase_token).then(res => {
                    this.firebaseService.startObservingFirebase(res.user.uid);
                    this.firebaseService.sessionActiveStatus$.subscribe((active: boolean) => {
                        if (!active) {
                            this.logout();
                        }
                    });
                }, error => {
                    console.error(error);
                });
            }, tokenError => {
                console.error(tokenError);
            });
        });
    }

    resendVerificationEmail(): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.getTokenType()} ${this.getToken()}`
        });

        return this.http.post(AuthService.authUrl + '/users/email_verification_request/',
            {}, {headers: headers});
    }

    private setSafeSuperUser(is_safe_super_user: boolean) {
        this.isSafeSuperUserSubject.next(is_safe_super_user);
        if (is_safe_super_user) {
            try {
                localStorage.setItem(AuthService.storageKeys.isSafeSuperUser, is_safe_super_user.toString());
            } catch (ex) {
                console.log(ex);
            }
        } else {
            try {
                localStorage.removeItem(AuthService.storageKeys.isSafeSuperUser);
            } catch (ex) {
                console.log(ex);
            }
        }
    }

    isSafeSuperUser() {
        return this.isSafeSuperUserSubject.getValue();
    }

    hasDataExportAccess() {
    // Note: Block CSV download for staff users
        return !this.isSafeSuperUser();
    }


    getMfaAccessToken(): Observable<any> {
        return this.ssoService.getMfaAccessToken()
    }

    requestMFAGrant(): void {
        this.ssoService.requestMFAGrant()
    }

    fetchOrganization(): Observable<any> {
        const argsObject = {};
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `${this.getTokenType()} ${this.getToken()}`
        });
        argsObject['headers'] = headers;
        return this.http.get(AuthService.authUrl + '/myorganizations/?default=true', argsObject).pipe(
            catchError((response: any): Observable<any> => {
                return observableThrowError(response);
            }));
    }

    getSuperAdminOrganizationsList() {
        return this.getOrganizationsList();
    }

    validateCurrentOrgId() {
        return this.organizationsListSubject.getValue().filter(res => res.id === this.getCurrentOrgId()).length === 1
    }

    appendOrgToList(currentOrg) {
        const orgList = []
        orgList.push(currentOrg)
        for (const org of this.organizationsListSubject.getValue()) {
            orgList.push(new Organization(org))
        }
        this.organizationsListSubject.next(orgList)
    }

    updateOrgInMyOrgList(updatedOrg) {
        const orgList = []
        for (const org of this.organizationsListSubject.getValue()) {
            if (org.id === updatedOrg.id) {
                orgList.push(new Organization(updatedOrg))
            } else {
                orgList.push(new Organization(org))
            }
        }
        this.organizationsListSubject.next(orgList)
    }

    appendOrganizationsToList( orgsList ) {
        let list = [];
        list = this.organizationsListSubject.getValue()
        list = list.concat(orgsList);
        this.organizationsListSubject.next(list);
    }

    setCurrentProduct(product) {
        if (product) {
            localStorage.setItem(AuthService.storageKeys.productType, product);
        } else {
            localStorage.setItem(AuthService.storageKeys.productType, 'QrCodes');
        }
    }

    getCurrentProduct(): string {
        return localStorage.getItem(AuthService.storageKeys.productType);
    }


    checkSubscriptionState(user: User) {

        if ((user.subscription.qr && user.subscription.qr.state !== SUBSCRIPTION_STATE.Expired)
            || (user.subscription.dbc && user.subscription.dbc.state !== SUBSCRIPTION_STATE.Expired)) {

            if (user.hasQRAccess(this.getCurrentOrgId()) && user.subscription.qr.state === SUBSCRIPTION_STATE.Expired) {
                this.setupDashboardBasedOnProduct(PRODUCT_TYPES.DBC)
            }
            if (user.hasDBCAccess(this.getCurrentOrgId()) && user.subscription.dbc.state === SUBSCRIPTION_STATE.Expired) {
                this.setupDashboardBasedOnProduct(PRODUCT_TYPES.QR)
            }

            return false
        }

        return true;
    }

    private setupCurrentProduct(user) {

        if  (!user) {
            return
        }

        switch (this.getCurrentProduct()) {

            case DASHBOARD_MENU.QRCODES:
                if (user.organization.feature_permissions.qr) {
                    this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.QRCODES)
                } else if (user.organization.feature_permissions.dbc) {
                    this.setCurrentProduct(DASHBOARD_MENU.CARDS)
                    this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.CARDS)
                }
                break;
            case DASHBOARD_MENU.CARDS:
                if (user.organization.feature_permissions.dbc) {
                    this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.CARDS)
                } else if (user.organization.feature_permissions.qr) {
                    this.setCurrentProduct(DASHBOARD_MENU.QRCODES)
                    this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.QRCODES)
                }
                break;
            default:
                if (user.organization.feature_permissions.qr) {
                    this.setCurrentProduct(DASHBOARD_MENU.QRCODES)
                    this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.QRCODES)
                } else if (user.organization.feature_permissions.dbc) {
                    this.setCurrentProduct(DASHBOARD_MENU.CARDS)
                    this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.CARDS)
                }

        }


    }

    setupDashboardBasedOnProduct(product: PRODUCT_TYPES) {

        switch (product) {

            case PRODUCT_TYPES.QR:
                this.setCurrentProduct(DASHBOARD_MENU.QRCODES)
                this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.QRCODES)
                break;
            case PRODUCT_TYPES.DBC:
                this.setCurrentProduct(DASHBOARD_MENU.CARDS)
                this.dashboardService.setCurrentDashboard(DASHBOARD_MENU.CARDS)
                break;

        }


    }

    checkSubscriptionStateForCurrentProduct() {

        switch (this.getCurrentProduct()) {

            case DASHBOARD_MENU.QRCODES:
                return this.getUser().hasQRAccess(this.getCurrentOrgId())
            case DASHBOARD_MENU.CARDS:
                return this.getUser().hasDBCAccess(this.getCurrentOrgId())

        }
    }

    private handleStorageChange(event: StorageEvent): void {
        if (event.key === AuthService.storageKeys.user) {
            const user = new User(JSON.parse(event.newValue))
            this.setUser(user);
        }
        if ((event.storageArea === localStorage) && (event.key === AuthService.storageKeys.user)) {
            this.storageChangeSubject.next(event);
        }
    }
}
